/*Container*/
#container{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.8);
	user-select: none;
	display: none;
	z-index: 1;
}

/*Wrap*/
#container .wrap{
	padding: 0px 0 10px 0;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

/*Center*/
#container .center {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

/*Image*/
#container img {
	display: block;
	width: auto;
	height: 65vh;
	max-width: 550px;
	max-height: 300px;
	min-height: 200px;
	object-fit: contain;
	/*animation: rotation 1s infinite steps(11);*/
}

/*@keyframes rotation {
	from {
		transform: rotate(359deg);
	}
	to {
		transform: rotate(0deg);
	}
}*/

/*Message*/
#container #message{
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
	color: #F2F2F2;
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-bottom: 20px;
	z-index: 1;
}

#container #message h1{
	font-size: 70px;
	font-weight: 700;
	margin: 0;
}

#container #message p{
	font-size: 18px;
	font-weight: 500;
}

/*Buttons*/
#container #buttons{
	display: flex;
	gap: 20px;
}

#container .button{
	border: 2px solid #F2F2F2;
	background-color: black;
	color: #F2F2F2;
	border-radius: 8px;
	padding: 10px 20px;
	z-index: 1;
}

#container .button:hover{
	background-color: #F2F2F2;
	color: #000;
	cursor: pointer;
}

#container .button p{
	font-weight: 600;
	font-size: 18px;
}